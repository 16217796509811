<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <label for="type"> Type</label>
        <b-form-select v-model="type" :options="types" @change="onchange"></b-form-select>
      </div>
    </div>
    <div class="row mt-5 d-flex align-items-end">
      <div v-if="type === '0' || type === '1'" class="col-md-6">
        <label for="amount">Amount</label>
        <b-form-input
          v-model="amount"
          type="number"
          min="1"
          max="999"
          class="w-100"
          placeholder="Amount"
        ></b-form-input>
      </div>
      <div v-if="type === '0'" class="col-md-6">
        <b-form-select v-model="selected">
          <b-form-select-option :value="null">Please select an option</b-form-select-option>
          <b-form-select-option v-for="(opt, idx) in list" :key="idx" :value="opt">{{ opt }}</b-form-select-option>
        </b-form-select>
      </div>
    </div>
    <div v-if="type === '2'" class="row mt-5 d-flex align-items-end">
      <div class="col-md-6">
        <label for="amount">Multiplier</label>
        <b-form-select v-model="multiplier" :options="multipliers"></b-form-select>
      </div>
      <div class="col-md-6">
        <label for="amount">No.of Hours</label>
        <b-form-select v-model="noOfHours" :options="durations"></b-form-select>
      </div>
    </div>
    <div class="d-flex mt-3 justify-content-center">
      <button @click="onSubmit" :disabled="isDisabled" class="btn btn-primary">Save</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    typeOfAction: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      amount: null,
      type: null,
      types: [
        { value: null, text: 'Please select an option' },
        { value: '0', text: 'Cash' },
        { value: '1', text: 'Super Cash' },
        { value: '2', text: 'Boost' },
      ],
      selected: null,
      list: [
        '',
        'K',
        'M',
        'B',
        'T',
        'aa',
        'ab',
        'ac',
        'ad',
        'ae',
        'af',
        'ag',
        'ah',
        'ai',
        'aj',
        'ak',
        'al',
        'am',
        'an',
        'ao',
        'ap',
        'aq',
        'ar',
        'as',
        'at',
        'au',
        'av',
        'aw',
        'ax',
        'ay',
        'aZ',
        'ba',
        'bb',
        'bc',
        'bd',
        'be',
        'bf',
        'bg',
        'bh',
        'bi',
        'bj',
        'bk',
        'bl',
        'bm',
        'bn',
        'bo',
        'bp',
        'bq',
        'br',
        'bs',
        'bt',
        'bu',
        'bv',
        'bw',
        'bx',
        'by',
        'bZ',
      ],
      multiplier: null,
      noOfHours: null,
      durations: [
        { value: null, text: 'Please select an option' },
        { value: 600000, text: '10 mins' },
        { value: 900000, text: '15 mins' },
        { value: 1800000, text: '30 mins' },
        { value: 3600000, text: '1 hr' },
        { value: 7200000, text: '2 hrs' },
        { value: 10800000, text: '3 hrs' },
        { value: 14400000, text: '4 hrs' },
        { value: 21600000, text: '6 hrs' },
        { value: 43200000, text: '12 hrs' },
        { value: 86400000, text: '1 day' },
        { value: 259200000, text: '3 days' },
        { value: 604800000, text: '7 days' },
      ],
      multipliers: [
        { value: null, text: 'Please select an option' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      selectedDay: 'selectedDay',
      selectedDate: 'selectedDate',
    }),
    isDisabled() {
      if (!this.type) {
        return true;
      } else {
        if (
          ((this.type === '0' || this.type === '1') && (!this.amount || this.amount < 1)) ||
          (this.type === '2' && (!this.noOfHours || !this.multiplier))
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

  },
  methods: {
    ...mapMutations({
      populateCalendarCell: 'populateCalendarCell',
      populateEventCell: 'populateEventCell',
    }),
    onchange(){
      if (this.type.values==null){
      this.amount= null,
      this.selected= null,
      this.noOfHours=null,
      this.multipliers.value=null,
      this.multiplier=null,
      this.durations.value=null,
      this.list= [
        '',
        'K',
        'M',
        'B',
        'T',
        'aa',
        'ab',
        'ac',
        'ad',
        'ae',
        'af',
        'ag',
        'ah',
        'ai',
        'aj',
        'ak',
        'al',
        'am',
        'an',
        'ao',
        'ap',
        'aq',
        'ar',
        'as',
        'at',
        'au',
        'av',
        'aw',
        'ax',
        'ay',
        'aZ',
        'ba',
        'bb',
        'bc',
        'bd',
        'be',
        'bf',
        'bg',
        'bh',
        'bi',
        'bj',
        'bk',
        'bl',
        'bm',
        'bn',
        'bo',
        'bp',
        'bq',
        'br',
        'bs',
        'bt',
        'bu',
        'bv',
        'bw',
        'bx',
        'by',
        'bZ',
      ],
      this.amount=null

      }
      

    },
    onSubmit() {
      
      const data = {
        reward_type: this.type,
        amount: this.type == 2 ? 0 : this.amount + ' ' + (this.selected ? this.selected : ''),
        multiplier: this.multiplier ? this.multiplier : 0,
        duration: this.noOfHours ? this.noOfHours : 0,
      };

      if (this.typeOfAction === 'events') {
        this.populateEventCell({
          cell: this.selectedDate,
          data,
          vm: this,
        });
      } else {
        this.populateCalendarCell({
          cell: this.typeOfAction === 'events' ? this.selectedDate : this.selectedDay,
          data,
          vm: this,
        });
      }
    },

  },
};
</script>

<style lang="scss" scoped>
label {
  color: #3c4eba;
  font-weight: 500;
}
</style>
